// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// For tailwind css
import "../css/application.css"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";

// Internal imports
import initiSidebarToggle from '../components/init_sidebar_toggle.js';
import { initIonRangeSlider } from '../components/init_ion_range_slider';
import initSelect2 from '../components/init_select2';
import initJqueryMask from '../components/init_jquery_mask';
import initCopyButton from '../components/init_copy_button';
import initBirthdayDate from '../components/init_birthday_date';
import initSelectTab from '../components/init_select_tab';
import initFunctionChoice from "../components/functions_choices";
import {initAutocomplete} from "../components/init_places_autocomplete";
import initpopImagePreview from "../components/init_pop_image_preview";
import initJobForm from "../components/init_job_form";
import initSelectRequestApplications from "../components/init_select_request_applications";
import initModalShowOnLoad from "../components/init_modal_show_on_load";
import initJcropper from "../components/init_jcropper";
import initFormSubmit from '../components/init_form_submit';
import initVerificationCode from '../components/init_verification_code';
import initShowContactInfoModalToProfessional from '../components/init_show_contact_info_modal_to_professional';
import '../components/init_map';
import toggleSwitch from "../components/init_toggle_switch.js"

document.addEventListener('turbolinks:load', () => {
  if(document.querySelector(".shift-fields")){
    import(/* webpackChunkName: "initShiftForm" */ '../components/init_shift_form')
      .then(({ default: initShiftForm }) => {
        initShiftForm();
      })
      .catch(error => {
        console.error("An error occurred while loading the component", error)
      });
  }
  if(document.querySelector('.work-type')){
    import(/* webpackChunkName: "initJobProfileForm" */ '../components/init_job_profile_form')
      .then(({ default: initJobProfileForm }) => {
        initJobProfileForm();
      })
      .catch(error => {
        console.error("An error occurred while loading the component", error)
      });
  }
  if(document.getElementById('updateProfileModal')){
    import(/* webpackChunkName: "initEditProfessionalProfileModal" */ '../components/init_edit_professional_profile_modal')
      .then(({ default: initEditProfessionalProfileModal }) => {
        initEditProfessionalProfileModal();
      })
      .catch(error => {
        console.error("An error occurred while loading the component", error)
      });
  }
  if(document.querySelector('.select-professional')){
    import(/* webpackChunkName: "initProfessionalPdfSelection" */ '../components/init_professional_pdf_selection')
      .then(({ default: initProfessionalPdfSelection }) => {
        initProfessionalPdfSelection();
      })
      .catch(error => {
        console.error("An error occurred while loading the component", error)
      });
  }
  initiSidebarToggle();
  initIonRangeSlider();
  initSelect2();
  initBirthdayDate();
  initCopyButton();
  initSelectTab();
  initJqueryMask();
  initFunctionChoice();
  initAutocomplete();
  initSelectRequestApplications();
  initpopImagePreview();
  initJobForm();
  initModalShowOnLoad();
  initJcropper();
  initFormSubmit();
  initVerificationCode();
  initShowContactInfoModalToProfessional();
  toggleSwitch();
});
