const toggleSwitch = () => {
  const switcher = document.getElementById('customize-job-vacancy');
  const container = document.getElementById('customize-job-vacancy-container');

  if (switcher) {
    switcher.addEventListener('change', () => {
      if (switcher.checked) {
        container.style.display = 'block'; // Mostra o container
      } else {
        container.style.display = 'none'; // Esconde o container
      }
    });
  }
}

export default toggleSwitch;
